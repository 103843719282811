.custom-header{padding: 0 !important;}

.ionrangestepper-margin{
  padding-top: 45px;
}
.slider-disagree{
  text-align: left;
  font-size: 12px;
  font-weight: 900;
  padding-bottom: 10px;
}
.slider-agree{
  text-align: right;
  font-size: 12px;
  font-weight: 900;
  padding-bottom: 10px;
}
.home-slider {
  height: 220px;
}
.dashboard-main-content {
  padding: 25px !important;
}

.heading-section {
  padding-left: 5px;
  display: inline;
}
#error_explanation{
  border: 2px solid #858585;
  padding: 7px;
  padding-bottom: 0;
  margin-bottom: 20px;
  background-color: #f0f0f0;
}

#error_explanation h2 {
  text-align: left;
  font-weight: bold;
  padding: 5px 5px 5px 15px;
  font-size: 14px;
  margin: -7px;
  margin-bottom: 0px;
  background-color: #858585;
  color: #fff;
}

#error_explanation ul {
  padding-top: 10px;
}

#error_explanation ul li {
  font-size: 12px;
  list-style: square;
}

.alert{
  padding: 20px;
  margin-left: 24px;
}

.alert-success{
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.alert-alert{
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}

.alert-notice{
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.grey-panel .panel-heading{background-color: grey; color:#fff;padding: 10px;}

.grey-panel .panel-content{padding: 30px;background-color: #fff;min-height: 230px;}

.pagination  .current {
  background-color: #337ab7;
  color: #FFF;
  cursor: default;
  z-index: 2;
}

.pagination .disabled { 
  color: #777777;
  background-color: white;
  cursor: not-allowed;
}

.pagination span{padding: 5px; border: 1px solid grey;}
.pagination em{padding: 5px; border: 1px solid grey; color: #23527c;}
.pagination a{padding: 5px; border: 1px solid grey;color: #23527c;}

.align_left{
  float: left!important;
}
.blue-dot-pdf1{position: relative;}
.blue-dot-pdf1:after{position: absolute;left:5px;top:35px;content:"";height: 8px;width: 8px;border-radius: 4px;background-color: #447dff;}
.black-dot-pdf{position: relative;}
.black-dot-pdf:after{position: absolute;left:5px;top:18px;content:"";height: 8px;width: 8px;border-radius: 4px;background-color: #447dff;}
.purple-dot-pdf{position: relative;}
.purple-dot-pdf:after{position: absolute;left:5px;top:35px;content:"";height: 8px;width: 8px;border-radius: 4px;background-color: #bf66ff;}
.blue-dot-pdf{position: relative;}
.blue-dot-pdf:after{position: absolute;left:5px;top:35px;content:"";height: 8px;width: 8px;border-radius: 4px;background-color: #447dff;}
.red-dot-pdf{position: relative;}
.red-dot-pdf:after{position: absolute;left:5px;top:35px;content:"";height: 8px;width: 8px;border-radius: 4px;background-color: #ff004b;}

.purple-bullet{color: #bf66ff;font-size: 12px !important;}
.proccess-no span{width: 8% !important;}
.proccess-bar span{width: 8% !important;}

.grey-dot{position: relative;}
.grey-dot:after{position: absolute;left:25px;top:30px;content:"";height: 8px;width: 8px;border-radius: 4px;background-color: grey;}

#report_doughnut_chart{
  //margin-left: 50px;
  width: 225px !important;
  height: 225px !important;
}

.reduce-button-width{
  width: 125px!important;
  min-width: 125px!important;
}

.panel-content p{
  font-size: 12px;
}

.panel-content small{
  font-size: 12px;
}

.panel-content h4{
  font-weight: 300;
  margin: 0 0 10px 0;
  font-size: 20px;
}
#question-title{
  height: 100px;
  font-weight: 300;
  color: #3C3C3B;
  margin: 0 0 10px 0;
  font-size: 22px;
  font-family: 'weissenhof_grotesklight';
}
.second-circle{
  width: 225px !important;
}
.circle-height{
  min-height: 260px;
}

.assessment-title{
  font-weight: 300;
  color: #fff;
  margin: 0 0 10px 0;
  font-size: 15px;
}

.assessment-description{
  font-weight: 300;
  margin: 0 0 10px 0;
  font-size: 14px;
  color: #666669;
  min-height: 100px;
}

.assessment-description:first-letter {
  text-transform: uppercase;
}

.rangestepper-margin{
  width: 97%;
}
.rangestepper.subscript{
  opacity: 99999;
}

#default-btn{
  display: inline-block;
  padding: 10px 30px;
  border-radius: 30px;
  min-width: 150px;
  text-decoration: none;
  color: #fff;
  background-color: #fff;
  border: 0;
  height: 40px;
  line-height: 25px;
  outline: 0;
}


.fixed-pager .bx-wrapper .bx-pager {
  text-align: right !important;
  position: relative !important;
  top: -525px !important;
  padding: 0 !important;
  width: auto !important;
}
.edit_user .form-field label{
  color: #666669 !important;
}

.mark-complete-button{
  margin-top: 7px;
}

.text-black-color label{
  color: #2e2b2b!important;
}

.placeholder-color::-webkit-input-placeholder {
  color: #fff!important; 
} 
.placeholder-color::-moz-placeholder { 
  color: #fff!important; 
}
.placeholder-color:-ms-input-placeholder { color: #fff!important; } 
.placeholder-color:-moz-placeholder { color: #fff!important; }

.login-page .form-field input[type="text"],.login-page .form-field input[type="email"],.login-page .form-field input[type="password"]{border-bottom: 2px solid #fff !important;}
.signup-page.login-page .form-field select{border-bottom: 2px solid #fff !important;}

.custom-ui {
    text-align: center;
    width: 500px;
    padding: 20px;
    background: #fff;
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
    border: 1px solid #ddd;
    color: #fff;
}